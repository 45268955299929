//
// react-select-async-paginate
//

.react-select-async-paginate {
  input[type]{
    &:focus{
      border-color: transparent !important;
      box-shadow: none;
    }
  }
  div[class$="control"] {
    background: transparent;
    color: #636c74;
    border-color: #BABFC5;
    font-size: 14px;
    div[class$="placeholder"] {
      color: #636c74;
      font-size: 14px;
    }
    svg[class$="Svg"] {
      fill: #636c74;
    }
  }
}