// Variables
$color-01 : #636c74;
$color-02 : #babfc5;
$color-03 : #1B75D0;
$color-04 : #155CA2;
$color-05 : #3b4045;
$color-06 : #f1f2f3;
$color-07 : #0c0d0e;
$color-08 : #e7700d;
$color-09 : #f4f4fe;
$color-10 : #babdee;

// common
@import './common/common';

// plugins
@import './plugins/react/select-async-paginate/override';
@import './plugins/react/js-pagination/override';

// pages
@import './pages/pages';

// components
@import './search/search';

// ui
@import './ui/jobs';