.nav-container {
  &.header-fixed{
    &.h-pos-fixed{
      top:0;
    }
  }
  .bar {
    .logo {
      @include media-breakpoint-lg {
        max-height: 50px;
        top: 0;
      }
    }
    .menu-horizontal {
      top: 0;
      padding-right: 15px;
      @include media-breakpoint-md {
        padding-right: 0;
      }
      li {
        &.d-line {
          display: none;
          @include media-breakpoint-md {
            display: inline-block;
          }
          width: 1px;
          position: relative;
          z-index: 0;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 5.5px;
            width: 1px;
            height: 18px;
            background-color: $color-02;
          }
        }
        &:not(:last-child) {
          @include media-breakpoint-lg {
            margin-right: 22px;
          }
        }
        a {
          font-size: 13px !important;
          color: $color-01 !important;
          text-transform: none;
          font-weight: 400 !important;
          letter-spacing: 0;
        }
      }
    }
  }
}
