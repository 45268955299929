.footer {
  .social-list {
    display: flex;
    align-items: center;
    @include media-breakpoint-md {
      justify-content: end;
    }
    li {
      &:not(:last-child){
        margin-right: 12px;
      }
      a {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          fill: white;
        }
      }
      &.small {
        svg {
          height: 17px;
          width: 17px;
        }
      }
    }
  }
}
