//
// react-js-pagination
//

.react-js-pagination {
  justify-content: center;
  margin-top: 1.85714286em !important;
  .page-item {
    .page-link {
      border-color: #babfc5;
      color: #636c74 !important;
      &:hover {
        background-color: #d6d9dc;
      }
    }
    &.active{
      .page-link {
        background-color: $color-08 !important;
        border-color: $color-08 !important;
        color: #fff !important;
      }
    }
  }
}
