.pages {
  &.ui-jobs {
    // Banner
    section {
      &.comp-search-banner {
        padding-top: 24px;
        padding-bottom: 24px;
        @include media-breakpoint-lg {
          padding-bottom: 11px;
        }
      }
    }
    // Job Details
    .j-details {
      padding: 0 0 0 5px;
      .job-name {
        color: $color-05;
        font-size: 15px;
        font-weight: 600;
      }
      .c-name {
        color: $color-03;
      }
      .detail-c-name {
        color: $color-05;
      }
      .salary {
        margin: 3px 0;
        display: flex;
        span {
          background-color: $color-06;
          display: inline-block;
          border-radius: 2px;
          color: $color-05;
          font-size: 12px;
          padding: 4px;
          line-height: 1;
          text-wrap: nowrap;
          & + span {
            margin-left: 4px;
          }
        }
      }
      .posted {
        margin-top: 10px;
        font-size: 11px;
        color: $color-01;
      }
    }
    //job-cards
    .job-cards {
      .job-list-item {
        color: $color-01;
        font-weight: 400;
        font-size: 13px;
        .row {
          &.boxed {
            &.boxed--border {
              border-color: #e3e6e8;
            }
            padding: 21px 21px 18.2px 85px;
            min-height: 106px;
            .j-details {
              .c-name {
                margin: 3px 0;
              }
            }
            &:hover {
              background-color: $color-06;
            }
            &.active {
              &.boxed--border {
                border-color: #e76e0e;
              }
            }
          }
        }
      }
    }
    //search-job-detail-container
    .search-tabs {
      .job-description {
        //top: 184px !important;
        .search-job-detail {
          .search-job-detail-container {
            max-height: 74.8vh;
            border-color: #e3e6e8;
            .info {
              .j-details {
                .job-name {
                  font-size: 20px;
                }
              }
            }
            .post-text {
              color: $color-05;
            }
            .btm-button {
              .buttons {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
