.comp-search-banner {
  &.pos-fixed {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    @include media-breakpoint-lg {
      padding-bottom: 11px !important;
    }
  }
  .boxed {
    margin-bottom: 0;
    padding: 0 !important;
  }
}
