.on-boarding-banner{
  background-color: $color-09;
  border: 1px solid $color-10;
  font-size: 13px;
  color: $color-05;
  .container{
    padding: 16px;
    @include media-breakpoint-lg {
      padding: 8px 32px 6px 32px;
    }
  }
  a{
    color: $color-05;
    &:not(.i-close){
      text-decoration: underline;
      font-weight: 400;
    }
    &.i-close{
      font-size: 12px;
    }
    &:hover{
      color: $color-05;
    }
  }
}
