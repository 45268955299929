input[type]{
    background-color: transparent;
    color: #0c0d0e;
    border-color: #BABFC5;
    font-size: 14px;
    &::placeholder{
        color: #636c74;
        font-size: 14px;
    }
    &:focus{
        border-color: #1B75D0 !important;
        box-shadow: 0 0 0 4px rgba(0, 116, 204, 0.15);
    }
}

// Buttons
.btn{
    &.btn--primary{
        background-color: $color-03;
        font-size: 14px;
        text-transform: capitalize;
        height: 39px;
        padding-top: 6px;
        padding-bottom: 6px;
        font-weight: 400;
        &:hover{
            background-color: $color-04;
        }
        .btn__text{
            font-weight: 400;
        }
    }
}

// footer
footer{
    background-color: #0c0d0e;
    &.bg--primary{
        background-color: #0c0d0e;
    }
    color: #BABFC5;
    *{
        color: #BABFC5 !important;
    }
    .social-list{
        img{
            opacity: 1;
        }
    }
    .type--fine-print:not(p){
        opacity: 1;
    }
    &.space--sm{
        height: 8em !important;
        .container{
            .mt-5{
                margin-top: 5px !important;
            }
            .pb-5{
                padding-bottom: 5px !important;
            }
        }
    }
}
